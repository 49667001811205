
import router from '@/router'
import { defineComponent, ref, onMounted } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import 'dayjs/locale/ja';
import 'dayjs/plugin/updateLocale';
import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import Constains from '@/components/Constains';
import { notification } from 'ant-design-vue';
import { timePickerProps } from 'ant-design-vue/lib/time-picker/time-picker';
  
  export default defineComponent({
    components: {
       // datepicker: VueDatePicker,
      //CheckOutlined,
      //EditOutlined,
    },
    setup() {
        const cookies  = useCookies();
        const projectColumns = [
            {
                title:'工事名',
                dataIndex: 'name',
                width: '30%',
            },
            {
                title:'略称',
                dataIndex: 'abbre',
                width: '10%',
            },
            {
                title:'状態',
                dataIndex: 'status',
                width: '10%',
            },
//            { title:'開始予定日', dataIndex: 'start', },
//            { title:'終了予定日', dataIndex: 'end', },
//            { title:'延長', dataIndex: 'extension',},
            {
                title:'操作',
                dataIndex: 'operation',
            }
        ];
      const projectSource = ref<any[]>([]);
      const registData:any = ref({
        name:null,
        abb:null,
        start:null,
        end:null,
        states:"0",
        })

        const registCode:any = ref({
          id:0,
          code:null,
          label:null,
          busho:[],
        })

        const bushoColumns = [
           {
                title:'部署名',
                dataIndex: 'label',
            },
            {
              title:'入力形式',
              dataIndex: 'edittype',
            },
            {
                title:'操作',
                dataIndex: 'operation',
                width: '30%',
            }
        ]

        const bushoSource = ref<any[]>([]);
          const registBusho:any = ref({
          id:0,
          label:null,
          edittype:null,
        })
        const bushoVisible = ref(false)

        const pointSource = ref<any[]>([]);
          const registPoint:any = ref({
          id:0,
          label:null,
        })
        const pointVisible = ref(false)

        const pointColumns = [
           {
                title:'場所名',
                dataIndex: 'label',
                
            },
            {
                title:'操作',
                dataIndex: 'operation',
                width: '30%',
            }
        ]


      const workColumns = [
        {
            title:'作業コード',
            dataIndex: 'code',
            width: '30%',
        },
        {
            title:'作業名',
            dataIndex: 'label',
        },
        {
            title:'部署',
            dataIndex: 'busho',
        },
        {
            title:'操作',
            dataIndex: 'operation',
            width: '10%',
        }
      ]

      const workSource = ref<any[]>([]);
      const visible = ref(false)
      const codeVisible = ref(false)
      const loading = ref(false)
      const selectbusho = ref<any[]>([]);
      let editID = 0;
      const isAuthority = ref(true)

      const editPlanColumns = [
        {
          title:'作業コード',
          dataIndex: 'code',
          width: '30%',
        },
        {
          title:'計画時間',
          dataIndex:'plan',
        },
        {
          title:'操作',
          dataIndex: 'operation',
          width: '10%',
        }
      ]
      const editPlanSource:any = ref([])
      const editPlanMode = ref(false)

      onMounted(async() => {
        /*
        var test = [
          {id:1,code:'1'},
          {id:2,code:'1-1'},
          {id:3,code:'2'},
          {id:14,code:'a-4'},
          {id:15,code:'t'},
          {id:4,code:'2-1'},
          {id:5,code:'2-5'},
          {id:14,code:'v'},
          {id:14,code:'t-2'},
          {id:15,code:'v-5'},
          {id:6,code:'2-3'},
          {id:7,code:'0'},
          {id:8,code:'0-1'},
        ]
        test.sort((a,b)=>{
            const a_bunrui = a.code.includes('-') ?  a.code.substring(0,a.code.indexOf('-')) : a.code
            const b_bunrui = b.code.includes('-') ?  b.code.substring(0,b.code.indexOf('-')) : b.code
            console.log(isNaN(Number(a_bunrui)) ,a_bunrui)
            if(a_bunrui!=b_bunrui) {
              if(isNaN(Number(a_bunrui)) && isNaN(Number(b_bunrui))) return a_bunrui < b_bunrui ? -1 :1
              if(isNaN(Number(a_bunrui))) return 1
              if(isNaN(Number(b_bunrui))) return -1
              return parseInt(a_bunrui) - parseInt(b_bunrui)
            } 
            if(!a.code.includes('-')) return -1
            if(!b.code.includes('-')) return 1
            const a_kubun = a.code.substring(a.code.indexOf('-')+1,a.code.length)
            const b_kubun = b.code.substring(b.code.indexOf('-')+1,b.code.length)
            return parseInt(a_kubun) - parseInt(b_kubun)
          })
        console.log(test)
        */
        await _reload()
      });

      const _reload = async () => {
        loading.value = true
        projectSource.value = []
        workSource.value = []
        bushoSource.value = []
        pointSource.value = []
        selectbusho.value = []//[{value:0,label:'無登録',}]

        var token = cookies.cookies.get('token');
        if(token == null){
          if(Constains.autoLogout){
          //ログインページに飛ばす
          router.push('/login');
          return;
          }
        }

      //ログインユーザー情報を取得
      try{
        let res = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token,{headers:{
            'Authorization' :'Bearer '+token, }});
        if(res.status == 200){
          let result = res.data;
          if(result.activate == 0) {
            if(Constains.autoLogout){
              notification.error({
                message: '非アクティブなアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
          }
          isAuthority.value = result.authority == 1
         } else if(res.status==400) {
          //ログアウトしてログイン画面に
          if(Constains.autoLogout){
            cookies.cookies.remove('token');
              notification.error({
                message: '無効なアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
         }
        } catch (e) {
          notification.error({
                message: 'ログインに失敗しました.',
              })
         if(Constains.autoLogout) return
        }

        //開発計画を取得
        let res = await axios.get(Constains.tracerbaseURL+'/api/Projects',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data.reverse();
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            projectSource.value.push({
              code:result['id'],
              name:result['name'],
              abbre:result['abbreviation'],
              start:result['start_date'] == null? null: new Date(result['start_date']).toLocaleDateString(),
              end:result['end_date'] == null? null: new Date(result['start_date']).toLocaleDateString(),
              status:result["isfin"] == 0?'作業中':'完了',
              isfin:result["isfin"].toString()
            });
          }
        } else {
          //tokenが無効化されているのでログインページに飛ばす
          //router.push('/login');
          //return;
        }

        //部署を取得
        res = await axios.get(Constains.tracerbaseURL+'/api/Busho',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            bushoSource.value.push({
              id:result['id'],
              label:result['label'],
              edittype:result['edittype'],
            });
            selectbusho.value.push({
              value:result['id'],
              label:result['label'],
              edittype:result['edittype'],
            })
          }
        }

        //作業コードを取得
        res = await axios.get(Constains.tracerbaseURL+'/api/Codes',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            workSource.value.push({
              id:result['id'],
              code:result['code'],
              label:result['label'],
              busho:result['busho'],
            });
          }
          workSource.value.sort((a,b)=>{
            const a_bunrui = a.code.includes('-') ?  a.code.substring(0,a.code.indexOf('-')) : a.code
            const b_bunrui = b.code.includes('-') ?  b.code.substring(0,b.code.indexOf('-')) : b.code
            if(a_bunrui!=b_bunrui) {
              if(isNaN(Number(a_bunrui)) && isNaN(Number(b_bunrui))) return a_bunrui < b_bunrui ? -1 :1
              if(isNaN(Number(a_bunrui))) return 1
              if(isNaN(Number(b_bunrui))) return -1
              return parseInt(a_bunrui) - parseInt(b_bunrui)
            } 
            if(!a.code.includes('-')) return -1
            if(!b.code.includes('-')) return 1
            const a_kubun = a.code.substring(a.code.indexOf('-')+1,a.code.length)
            const b_kubun = b.code.substring(b.code.indexOf('-')+1,b.code.length)
            return parseInt(a_kubun) - parseInt(b_kubun)
          })
        } 

        //場所を取得
        res = await axios.get(Constains.tracerbaseURL+'/api/Point',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            pointSource.value.push({
              id:result['id'],
              label:result['label'],
            });
          }
        } 
        loading.value = false
      }


      const edit = (record:any) => {
        if(!isAuthority.value) return
        editID = record.code
        registData.value.start = record.start
        registData.value.end = record.end
        registData.value.name = record.name
        registData.value.abb = record.abbre
        registData.value.states = record.isfin
        visible.value = true
      }

      const view = () =>{
        editID = 0
        registData.value.start = null
        registData.value.end = null
        registData.value.name = null
        registData.value.abb = null
        registData.value.states = "0"

        visible.value = true
      }

      const regist = async() => {
        loading.value = true
        let start = null;
        let end = null;
        if(registData.value.start !== null) {
          registData.value.start.setHours(0)
          registData.value.start.setMinutes(0)
          start = registData.value.start.toISOString()
        }
        if(registData.value.end !== null){
          registData.value.end.setHours(23)
          registData.value.end.setMinutes(59)
          end = registData.value.end.toISOString()
        } 

        if(registData.value.abb === null) registData.value.abb = ""
        let json = {
            id:editID,
            name:registData.value.name,
            abbreviation:registData.value.abb,
            start_date:start,
            end_date:end,
            isfin: parseInt(registData.value.states),
        }

        var token = cookies.cookies.get('token');
        if(editID == 0){
          try{
            const res = await axios.post(Constains.tracerbaseURL+'/api/Projects',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registData.value.name = null
              registData.value.abb = null
              registData.value.start = null
              registData.value.end = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        } else {
          try{
            const res = await axios.put(Constains.tracerbaseURL+'/api/Projects/'+editID,JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registData.value.name = null
              registData.value.abb = null
              registData.value.start = null
              registData.value.end = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        }

        visible.value = false;
        loading.value = false;
      }

      const deleteRecord = async() =>{
        var token = cookies.cookies.get('token');
        try{
            const res = await axios.delete(Constains.tracerbaseURL+'/api/Projects/'+editID,
              {
                headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, },
                data:{id:editID},
              });

            if(res.status == 200||res.status == 201||res.status == 204){
              visible.value = false
              await _reload()
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
      }

      const createCode = () => {
        registCode.value.id = 0
        registCode.value.code = null
        registCode.value.label = null
        registCode.value.busho = []
        codeVisible.value = true
      }

      const editCode = (record:any) => {
        if(!isAuthority.value) return
        registCode.value.id = record.id
        registCode.value.code = record.code
        registCode.value.label = record.label
        registCode.value.busho = record.busho
        codeVisible.value = true
      }

      const registWorkCode = async() => {
        loading.value = true
        let json = {
            id:registCode.value.id,
            code:registCode.value.code,
            label:registCode.value.label,
            busho:registCode.value.busho
        }
        var token = cookies.cookies.get('token');
        if(registCode.value.id == 0){
          try{
            const res = await axios.post(Constains.tracerbaseURL+'/api/Codes',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registCode.value.id = 0
              registCode.value.code = null
              registCode.value.label = null
              registCode.value.busho = []
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        } else {
          try{
            const res = await axios.put(Constains.tracerbaseURL+'/api/Codes/'+registCode.value.id,JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registData.value.name = null
              registData.value.abb = null
              registData.value.start = null
              registData.value.end = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        }

        codeVisible.value = false;
        loading.value = false;
      }

      const deleteCode = async() => {
        loading.value = true;
        var token = cookies.cookies.get('token');
        try{
            const res = await axios.delete(Constains.tracerbaseURL+'/api/Codes/'+registCode.value.id,
              {
                headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, },
                data:{id:registCode.value.id},
              });

            if(res.status == 200||res.status == 201||res.status == 204){
              codeVisible.value = false
              await _reload()
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
      }

      const createBusho = () => {
        registBusho.value.id = 0
        registBusho.value.label = ''
        registBusho.value.edittype = null
        bushoVisible.value = true
      }

      const editBusho = (record:any) => {
        if(!isAuthority.value) return
        registBusho.value.id = record.id
        registBusho.value.label = record.label
        registBusho.value.edittype = record.edittype
        bushoVisible.value = true
      }

      const registBushoData = async() => {
        loading.value = true
        let json = {
            id:registBusho.value.id,
            label:registBusho.value.label,
            edittype:registBusho.value.edittype == null ? 0 : registBusho.value.edittype
        }
        var token = cookies.cookies.get('token');
        if(registBusho.value.id == 0){
          try{
            const res = await axios.post(Constains.tracerbaseURL+'/api/Busho',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registBusho.value.id = 0
              registBusho.value.label = null
              registBusho.value.edittype = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        } else {
          try{
            const res = await axios.put(Constains.tracerbaseURL+'/api/Busho/'+registBusho.value.id,JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registBusho.value.id = 0
              registBusho.value.label = null
              registBusho.value.edittype = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        }

        bushoVisible.value = false;
        loading.value = false;
      }

      const deleteBusho = async () => {
        loading.value = true;
        var token = cookies.cookies.get('token');
        try{
            const res = await axios.delete(Constains.tracerbaseURL+'/api/Busho/'+registBusho.value.id,
              {
                headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, },
                data:{id:registBusho.value.id},
              });

            if(res.status == 200||res.status == 201||res.status == 204){
              bushoVisible.value = false
              await _reload()
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
      }

      const createPoint = () => {
        registPoint.value.id = 0
        registPoint.value.label = ''
        pointVisible.value = true
      }

      const editPoint = (record:any) => {
        if(!isAuthority.value) return
        registPoint.value.id = record.id
        registPoint.value.label = record.label
        pointVisible.value = true
      }

      const registPointData = async() => {
        loading.value = true
        let json = {
            id:registPoint.value.id,
            label:registPoint.value.label
        }
        var token = cookies.cookies.get('token');
        if(registPoint.value.id == 0){
          try{
            const res = await axios.post(Constains.tracerbaseURL+'/api/Point',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registPoint.value.id = 0
              registPoint.value.label = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        } else {
          try{
            const res = await axios.put(Constains.tracerbaseURL+'/api/Point/'+registPoint.value.id,JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registPoint.value.id = 0
              registPoint.value.label = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        }

        pointVisible.value = false;
        loading.value = false;
      }

      const deletePoint = async () => {
        loading.value = true;
        var token = cookies.cookies.get('token');
        try{
            const res = await axios.delete(Constains.tracerbaseURL+'/api/Point/'+registPoint.value.id,
              {
                headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, },
                data:{id:registPoint.value.id},
              });

            if(res.status == 200||res.status == 201||res.status == 204){
              pointVisible.value = false
              await _reload()
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
      }

      /*
      const getBushoLabel = (id:number) => {
        var index = bushoSource.value.findIndex(e=>e.id===id);
        if(index >= 0) return bushoSource.value[index].label
        else return null
      }
      */

      const getBushoLabel = (list:number[]) => {
        var labels:any[] = []

        list.forEach(id => {
          var index = bushoSource.value.findIndex(e=>e.id===id);
          if(index >= 0) labels.push(bushoSource.value[index].label)
        });
        return labels
      }

      const getPointLabel = (id:number) => {
        var index = pointSource.value.findIndex(e=>e.id===id);
        if(index >= 0) return pointSource.value[index].label
        else return null
      }

      let editPlanProject:any = {};
      let editPlanProjectLabel = ref('')

      const editPlan = async(project:any) => {
        loading.value = true;
        const projectID = project.code

        var token = cookies.cookies.get('token');
        editPlanMode.value = true
        const res = await axios.get(Constains.tracerbaseURL+'/api/ProjectPlans/'+projectID,{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          editPlanProject = project
          editPlanProjectLabel.value = project.name
          editPlanSource.value = []
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            editPlanSource.value.push({
              id:result['id'],
              projectid:projectID,
              code:result['code'],
              plan:result['plan'],
            })
          }
          editPlanSource.value.sort((a,b)=>{
            const a_bunrui = a.code.includes('-') ?  a.code.substring(0,a.code.indexOf('-')) : a.code
            const b_bunrui = b.code.includes('-') ?  b.code.substring(0,b.code.indexOf('-')) : b.code
            if(a_bunrui!=b_bunrui) {
              if(isNaN(Number(a_bunrui)) && isNaN(Number(b_bunrui))) return a_bunrui < b_bunrui ? -1 :1
              if(isNaN(Number(a_bunrui))) return 1
              if(isNaN(Number(b_bunrui))) return -1
              return parseInt(a_bunrui) - parseInt(b_bunrui)
            } 
            if(!a.code.includes('-')) return -1
            if(!b.code.includes('-')) return 1
            const a_kubun = a.code.substring(a.code.indexOf('-')+1,a.code.length)
            const b_kubun = b.code.substring(b.code.indexOf('-')+1,b.code.length)
            return parseInt(a_kubun) - parseInt(b_kubun)
          });
        } 

        editPlanSource.value.push(
            {
              id:0,
              projectid:projectID,
              code:null,
              plan:0,
            }
          )
        loading.value = false;
      }

      const selectCodeOptions = () => {
        const useCode:any = []
        editPlanSource.value.forEach(element => {
          useCode.push(element.code)
        });

        const options:any = []
        const tmp = workSource.value.filter(e=>!useCode.includes(e.code))
        tmp.forEach(code => {
          options.push(
            {
              value:code.code,
              label:code.code+':'+code.label,
            }
          )
        })

        return options
        //return workSource.value.filter(e=>!useCode.includes(e.code))
      }

      const AddPlan = () => {
        const lastPlan = editPlanSource.value.slice(-1)[0]
        if(lastPlan.code != null){
          editPlanSource.value.push(
            {
              id:0,
              projectid:editPlanProject.code,
              code:null,
              plan:0,
            }
          )
        }
      }

      const deletePlanIDList:any = []

      const deletePlan = (plan:any) => {
        console.log(plan)
        if(plan.id != 0) deletePlanIDList.push(plan.id)

        editPlanSource.value = editPlanSource.value.filter( function(item){
            return item.code !== plan.code
          }
        )
      }

      const editPlanSave = async() => {
        loading.value = true
        var token = cookies.cookies.get('token');
        editPlanSource.value.pop()
        const json = editPlanSource.value
        console.log('editPlanSave',json,editPlanProject)

        const res = await axios.post(Constains.tracerbaseURL+'/api/ProjectPlans',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
            console.log('addAchieve.states:'+res.status);
            console.log('addAchieve.response:'+JSON.stringify(res.data));

            if(res.status == 200||res.status == 201||res.status == 204){

              if(deletePlanIDList.length > 0){
                console.log('削除チェック',await axios.delete(Constains.tracerbaseURL+'/api/ProjectPlans',
                  {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, },
                  data:JSON.stringify(deletePlanIDList),
                  }
                  ))
              }
              notification.info({
                message: '保存が完了しました.',
                duration: 4.5,
              })

              await editPlan(editPlanProject)
            } else {
              //エラー表示
              notification.error({
                message: '保存に失敗しました.',
                duration: 4.5,
              })
            }
      }

      const editPlanCancel = () => {
        editPlanMode.value = false;
      }

      return {
        activeKey: ref('1'),
        projectColumns,
        projectSource,
        edit,
        registData,
        regist,
        view,
        visible,
        loading,
        deleteRecord,
        workSource,
        workColumns,
        editCode,
        codeVisible,
        createCode,
        registCode,
        registWorkCode,
        deleteCode,
        bushoColumns,
        bushoSource,
        createBusho,
        bushoVisible,
        editBusho,
        deleteBusho,
        registBusho,
        registBushoData,
        pointColumns,
        pointSource,
        createPoint,
        pointVisible,
        editPoint,
        deletePoint,
        registPoint,
        registPointData,
        getBushoLabel,
        getPointLabel,
        selectbusho,
        isAuthority,
        editPlan,
        editPlanMode,
        editPlanColumns,
        editPlanSource,
        AddPlan,
        deletePlan,
        selectCodeOptions,
        editPlanSave,
        editPlanCancel,
        editPlanProjectLabel,
      };
    },
  });
  